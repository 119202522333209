<template>
  <v-navigation-drawer
    id="app-drawer"
    app
    color="grey darken-2"
    dark
    floating
    mobile-breakpoint="991"
    persistent
    width="260"
    >
    <template v-slot:img="attrs">
      <v-img
        v-bind="attrs"
        gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)"
        />
    </template>
    <v-list-item two-line>
      <v-list-item-avatar color="white">
        <v-img
          src="/static/app/asmodeus-icon.png"
          height="34"
          contain
          />
      </v-list-item-avatar>
      <v-list-item-title class="title">
        Asmodeus
      </v-list-item-title>
    </v-list-item>
    <v-divider class="mx-3 mb-3" />
    <v-list nav>
      <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
      <div />
      <v-list-item
        v-for="(link, i) in links"
        :key="i"
        :to="link.to"
        active-class="primary white--text"
        >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-title v-text="link.text" />
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="row no-gutters mb-6">
        <div class="col" v-if="stats">
          <div class="pa-2 v-card v-sheet v-sheet--outlined">
            <v-progress-circular class="mx-1 mb-1"
              :rotate="270"
              :size="100"
              :width="9"
              :value="stats.kubernetes.allocated_cpu_percent | promFormat"
              :color=percent_color(stats.kubernetes.allocated_cpu_percent)
              >
              CPU&nbsp;<strong>{{ stats.kubernetes.allocated_cpu_percent | promFormat }}%</strong>
            </v-progress-circular>
          </div>
        </div>
        <div class="col" v-if="stats">
          <div class="pa-2 v-card v-sheet v-sheet--outlined">
            <v-progress-circular class="mx-1 mb-1"
              :rotate="270"
              :size="100"
              :width="9"
              :value="stats.kubernetes.allocated_memory_percent | promFormat"
              :color=percent_color(stats.kubernetes.allocated_memory_percent)
              >
              Mem&nbsp;<strong>{{ stats.kubernetes.allocated_memory_percent | promFormat }} %</strong>
            </v-progress-circular>
          </div>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    links: [{
        to: '/',
        icon: 'mdi-view-dashboard',
        text: 'Dashboard',
      },
      {
        to: '/maintenance',
        icon: 'mdi-alert-box-outline',
        text: 'Maintenance',
      },
      {
        to: '/settings',
        icon: 'mdi-hammer-screwdriver',
        text: 'Settings',
      }
    ],
  }),
  methods: {
    percent_color: function(value) {
      console.log('initiating percent_color')
      if (value >= 0.80) {
        return 'red'
      } else if (value >= 0.50 && value < 0.80) {
        return 'yellow'
      } else {
        return 'green'
      }
    }
  },
  computed: {
    stats() {
      return this.$store.state.stats
    }
  },
  mounted() {
    this.$store.dispatch("getStats");
  }
}
</script>
